.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #5c7a3b;
  line-height: 1.41176471;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #7eb151;
}
.button:active {
  background-color: #7eb151;
}
.desk {
  max-width: calc(100% - 112px);
}
@media only screen and (max-width: 1399px) {
  #home {
    height: 110px;
    overflow: hidden;
  }
  #home .logo {
    height: 85px;
  }
  .cb-scroll-triggered--active #home {
    height: 42px;
  }
}
.navbar .navbar-inner {
  padding: 20px 0;
}
.navbar:after {
  height: 7px;
  border-width: 0 0 100px 100vw;
}
.cb-scroll-triggered--active .navbar .navbar-inner {
  padding: 20px 0 25px;
}
.cb-scroll-triggered--active .navbar:after {
  border-width: 0 0 0 100vw;
}
.section--footer .cols {
  width: calc(100% + 28px);
  margin-left: -14px;
}
.section--footer .cols .col {
  width: 33.33333333%;
  border-bottom: none;
  border-left: 1px solid #7eb151;
  padding: 0 14px;
}
.section--footer .cols .col:first-child {
  border-left: none;
}
.cb-layout1 .skew {
  font-size: 34px;
  line-height: 1.32352941;
}
.cb-layout1 .area.base,
.cb-layout2 .area.base {
  width: 100%;
}
.cb-layout1 .area.base > .unit,
.cb-layout2 .area.base > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout1 .area.base .cb-album .body,
.cb-layout2 .area.base .cb-album .body,
.cb-layout1 .area.base .head,
.cb-layout2 .area.base .head,
.cb-layout1 .area.base .foot,
.cb-layout2 .area.base .foot,
.cb-layout1 .area.base .part,
.cb-layout2 .area.base .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout1 .area.base .tiny,
.cb-layout2 .area.base .tiny {
  width: 33.33333333%;
}
.cb-layout1 .area.base > .slim,
.cb-layout2 .area.base > .slim {
  width: 50%;
}
.cb-layout1 .area.base > .slim .head,
.cb-layout2 .area.base > .slim .head,
.cb-layout1 .area.base > .slim .foot,
.cb-layout2 .area.base > .slim .foot,
.cb-layout1 .area.base > .slim .part,
.cb-layout2 .area.base > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout1 .area.base > .slim.cb-album .body,
.cb-layout2 .area.base > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout1 .area.base > .slim .tiny,
.cb-layout2 .area.base > .slim .tiny {
  width: 50%;
}
.cb-layout1 .area {
  width: 100%;
}
.cb-layout1 .area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout1 .area .cb-album .body,
.cb-layout1 .area .head,
.cb-layout1 .area .foot,
.cb-layout1 .area .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout1 .area .tiny {
  width: 50%;
}
.cb-layout1 .area > .slim {
  width: 50%;
}
.cb-layout1 .area > .slim .head,
.cb-layout1 .area > .slim .foot,
.cb-layout1 .area > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout1 .area > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout1 .area > .slim .tiny {
  width: 50%;
}
.cb-layout2 .area.main {
  width: 100%;
}
.cb-layout2 .area.main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .area.main .cb-album .body,
.cb-layout2 .area.main .head,
.cb-layout2 .area.main .foot,
.cb-layout2 .area.main .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .area.main .tiny {
  width: 50%;
}
.cb-layout2 .area.main > .slim {
  width: 50%;
}
.cb-layout2 .area.main > .slim .head,
.cb-layout2 .area.main > .slim .foot,
.cb-layout2 .area.main > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .area.main > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .area.main > .slim .tiny {
  width: 50%;
}
.cb-layout3 .area.main,
.cb-layout3 .area.side {
  width: 100%;
}
.cb-layout3 .area.main > .unit,
.cb-layout3 .area.side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout3 .area.main .cb-album .body,
.cb-layout3 .area.side .cb-album .body,
.cb-layout3 .area.main .head,
.cb-layout3 .area.side .head,
.cb-layout3 .area.main .foot,
.cb-layout3 .area.side .foot,
.cb-layout3 .area.main .part,
.cb-layout3 .area.side .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout3 .area.main .tiny,
.cb-layout3 .area.side .tiny {
  width: 33.33333333%;
}
.cb-layout3 .area.main > .slim,
.cb-layout3 .area.side > .slim {
  width: 50%;
}
.cb-layout3 .area.main > .slim .head,
.cb-layout3 .area.side > .slim .head,
.cb-layout3 .area.main > .slim .foot,
.cb-layout3 .area.side > .slim .foot,
.cb-layout3 .area.main > .slim .part,
.cb-layout3 .area.side > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout3 .area.main > .slim.cb-album .body,
.cb-layout3 .area.side > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout3 .area.main > .slim .tiny,
.cb-layout3 .area.side > .slim .tiny {
  width: 50%;
}
.cb-layout1 .area.base,
.cb-layout2 .area.base {
  width: calc(100% + 20px);
  margin-left: -10px;
}
.cb-layout1 .area.base .unit .part,
.cb-layout2 .area.base .unit .part,
.cb-layout1 .area.base .unit .foot,
.cb-layout2 .area.base .unit .foot {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 10px;
}
.cb-layout1 .area.fareast .unit,
.cb-layout2 .area.fareast .unit {
  width: calc(100% - 28px);
  margin-left: 14px;
  margin-right: 14px;
}
.cb-layout1 .area.fareast .unit .part,
.cb-layout2 .area.fareast .unit .part,
.cb-layout1 .area.fareast .unit .foot,
.cb-layout2 .area.fareast .unit .foot {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  padding: 0 14px;
}
.cb-layout1 .area.fareast .unit.wide,
.cb-layout2 .area.fareast .unit.wide {
  width: calc(100%/3*2 - 28px);
}
.cb-layout1 .area.fareast .unit.slim,
.cb-layout2 .area.fareast .unit.slim {
  width: calc(100%/3 - 28px);
}
.cb-layout1 .area.fareast .unit.pure,
.cb-layout2 .area.fareast .unit.pure {
  margin-left: 0;
  margin-right: 0;
}
.cb-layout1 .area.fareast .unit.pure.wide,
.cb-layout2 .area.fareast .unit.pure.wide {
  width: 66.66666667%;
  margin-top: 30px;
}
.cb-layout1 .area.fareast .unit.pure.slim,
.cb-layout2 .area.fareast .unit.pure.slim {
  width: 33.33333333%;
}
.cb-layout1 .area {
  width: calc(100% + 28px);
  margin-left: -14px;
}
.cb-layout1 .area .unit .part,
.cb-layout1 .area .unit .foot {
  padding: 0 14px;
}
.cb-layout1 .area.north .unit.seam.wide,
.cb-layout1 .area.south .unit.seam.wide {
  width: 58.33333333%;
}
.cb-layout1 .area.north .unit.seam.slim,
.cb-layout1 .area.south .unit.seam.slim {
  width: 41.66666667%;
}
.cb-layout2 .container--main {
  width: 63.7195122%;
}
.cb-layout2 .container--side {
  float: right;
  width: 32.01219512%;
}
.cb-layout2 .area.main {
  width: calc(100% + 28px);
  margin-left: -14px;
  margin-top: 0;
}
.cb-layout2 .area.main .unit .part,
.cb-layout2 .area.main .unit .foot {
  padding: 0 14px;
}
.cb-layout2 .area.side {
  width: calc(100% + 20px);
  margin-left: -10px;
}
.cb-layout2 .area.side .unit .part,
.cb-layout2 .area.side .unit .foot {
  padding-left: 10px;
  padding-right: 10px;
}
.cb-layout2 .area.side .unit .part.tiny,
.cb-layout2 .area.side .unit .foot.tiny {
  width: 50%;
}
.cb-layout3 .container--main {
  float: right;
  width: 47.86585366%;
}
.cb-layout3 .container--side {
  width: 47.86585366%;
}
.cb-layout3 .area.main,
.cb-layout3 .area.side {
  width: calc(100% + 20px);
  margin-left: -10px;
}
.cb-layout3 .area.main .unit .part,
.cb-layout3 .area.side .unit .part,
.cb-layout3 .area.main .unit .foot,
.cb-layout3 .area.side .unit .foot {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 10px;
}
.unit.fold {
  width: calc(100% - 28px) !important;
  margin-left: 14px !important;
  margin-right: 14px !important;
}
.cb-layout3 .unit.fold {
  margin-left: 10px !important;
  margin-right: 10px !important;
  width: calc(100% - 20px) !important;
}
.cb-layout3 .unit.fold.flat {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
}
/*# sourceMappingURL=./screen-medium.css.map */